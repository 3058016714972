<template>
  <bg-modal v-model="isShowModal" button-main-text="Terapkan " button-second-text="Reset" :closeOnClickBackdrop="false"
    desktop-size="lg" @close="handleCloseModal" @click-main-action="actionSearchModal"
    @click-second-action="handleResetSearch" data-testid="filter-action-modal">
    <bg-text size="heading-4">Filter Penyewa</bg-text>
    <bg-text class="filter-action__subtitle" size="input-lg">Silakan pilih sesuai kebutuhan Anda</bg-text>
    <!-- BSE status -->
    <bg-text size="title-3" class="filter-action__subtitle-space-top">Akun BSE</bg-text>
    <div class="filter-action__full">
      <bg-select v-model="advancedSearchParams.bse" :options="bseOptions" placeholder="Pilih BSE" />
    </div>

    <!-- Tenant phase -->
    <bg-text size="title-3" class="filter-action__subtitle-space-top">Tahapan</bg-text>
    <div class="filter-action__wrapper">
      <div class="filter-action__options" data-testid="filter-action-phase">
        <bg-select v-model="advancedSearchParams.phase" :options="phases" placeholder="Pilih Fase"
          @input="handleChoosePhase" />
      </div>
      <div class="filter-action__options">
        <bg-select v-model="advancedSearchParams.status" :options="statusOptions[advancedSearchParams.phase]"
          placeholder="Pilih Status" />
      </div>
    </div>
    <!-- Followup Status -->
    <div v-if="!isProfile">
      <bg-text size="title-3" class="filter-action__subtitle-space-top">Status Follow-up</bg-text>
      <div class="filter-action__wrapper">
        <div class="filter-action__options">
          <bg-select v-model="advancedSearchParams.handled" :options="followupOptions" placeholder="Semua status" />
        </div>
      </div>
    </div>
    <bg-text size="title-3" class="filter-action__subtitle-space-top">Rentang Tanggal Update Status</bg-text>
    <div class="filter-action__wrapper">
      <div class="filter-action__date">
        <bg-text size="input-lg">Tanggal Mulai</bg-text>
        <bg-datepicker fixed-position="top" v-model="advancedSearchParams[`status_date[start]`]"
          placeholder="Pilih tanggal di sini" />
      </div>
      <div class="filter-action__date">
        <bg-text size="input-lg">Tanggal Akhir</bg-text>
        <bg-datepicker v-model="advancedSearchParams[`status_date[end]`]" fixed-position="top"
          placeholder="Pilih tanggal di sini"
          :disabled-dates="handleDisabledDate(advancedSearchParams[`status_date[start]`], 120)" />
      </div>
    </div>
    <bg-text size="title-3" class="filter-action__subtitle-space-top">Rentang Tanggal Check-in & Check-out</bg-text>
    <div class="filter-action__wrapper">
      <div class="filter-action__date">
        <bg-text size="input-lg">Tanggal Check-in</bg-text>
        <bg-datepicker fixed-position="top" v-model="advancedSearchParams.checkin_date"
          placeholder="Pilih tanggal di sini" :disabled="isDateRangeDisabled" @input="handlePhaseOptions"
          data-testid="filter-action-checkin-date" />
      </div>
      <div class="filter-action__date">
        <bg-text size="input-lg">Tanggal Check-out</bg-text>
        <bg-datepicker v-model="advancedSearchParams.checkout_date" placeholder="Pilih tanggal di sini"
          fixed-position="top" :disabled="isDateRangeDisabled" @input="handlePhaseOptions"
          :disabled-dates="handleDisabledDate(advancedSearchParams.checkin_date)" />
      </div>
    </div>
  </bg-modal>
</template>

<script>
import { BgModal, BgText, BgSelect, BgDatepicker } from 'bangul-vue';
import TenantCommunicationMixins from '../../mixins/tenantTracker';

export default {
  name: 'FilterActionModal',

  components: {
    BgText,
    BgModal,
    BgSelect,
    BgDatepicker,
  },

  data() {
    return {
      value: '',
      isShowModal: false,
      advancedSearchParams: {},
      exemptProperty: [
        'trackerId',
        'sort_by',
        'page',
        'limit',
        'sort_order',
        'tenant_name',
        'kost_name',
        'tenant_phone_number',
        'userId',
      ],
      filterProperties: [
        `status_date[start]`,
        `status_date[end]`,
        'bse',
        'phase',
        'status',
        'handled',
        'checkin_date',
        'checkout_date'
      ],
      followupOptions: [
        {
          label: 'Sudah di-followup',
          val: 'followed',
        },
        {
          label: 'Belum di-followup',
          val: 'notFollowed',
        },
      ],
      countFilter: 0,
      isDateRangeDisabled: false,
      phases: [],
      enabledPhase: ['checkin', 'checkout']
    };
  },

  computed: {
    storageParams() {
      return JSON.parse(sessionStorage.getItem(this.storageName));
    },
    storageUserTrackerId() {
      return JSON.parse(sessionStorage.getItem('userTrackerId'));
    },
    storageName() {
      return this.isProfile ? 'searchParamsProfile' : 'searchParams';
    },
  },

  mixins: [TenantCommunicationMixins],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.storageParams) {
      this.advancedSearchParams = {
        ...this.storageParams,
      };
      this.handleCountFilter();
      this.$emit('handle-filter-count', this.countFilter);
      this.handleSelectedDates();
      this.handleFollowUpDropdown();
      this.handleProfileSearchHistory();
      this.phases = this.phaseOptions;
    }
  },

  beforeDestroy() {
    if (this.isProfile) {
      const { userId, trackerId} = this.storageParams
      this.handleUserTrackerId(userId, trackerId);
    }
  },

  watch: {
    showModal(isOpen) {
      this.isShowModal = isOpen;
    },
  },

  methods: {
    handleDisabledDate(start, maxDateRange) {
      const startDate = new Date(start);
      const limitDate = maxDateRange ? new Date(
        new Date(startDate).setDate(startDate.getDate() + maxDateRange)) : null;
      return {
        to: startDate,
        from: limitDate,
      };
    },
    handleUserTrackerId(userId, trackerId) {
      sessionStorage.setItem(
        'userTrackerId',
        JSON.stringify({
          userId,
          trackerId
        })
      );
    },
    handleProfileSearchHistory() {
      if (this.isProfile) {
        const {userId, trackerId} = this.$route.params;
        const isSearchReset = !(userId === this.storageUserTrackerId?.userId && trackerId === this.storageUserTrackerId?.trackerId);

        if (isSearchReset) {
          this.handleResetParameters();
          this.$emit('action-search', this.storageParams);
        }
      }
    },
    handlePhaseOptions() {
      if (this.advancedSearchParams.checkin_date || this.advancedSearchParams.checkout_date) {
        this.phases = this.phaseOptions.filter(phase => this.enabledPhase.includes(phase.val));
      }
    },
    handleChoosePhase() {
      if (this.enabledPhase.includes(this.advancedSearchParams.phase)) {
        this.isDateRangeDisabled = false;
      } else {
        this.isDateRangeDisabled = true;

        delete this.advancedSearchParams.checkin_date;
        delete this.advancedSearchParams.checkout_date;
      }

      delete this.advancedSearchParams.status;
    },
    handleCountFilter() {
      let countMinus = 0;
      const allParams = {
        ...this.storageParams,
        ...this.advancedSearchParams,
      };
      this.exemptProperty.forEach(e => delete allParams[e]);
      if (allParams[`status_date[start]`] && allParams[`status_date[end]`]) {
        countMinus += 1;
      }
      if (allParams.checkin_date && allParams.checkout_date) {
        countMinus += 1;
      }
      if (allParams.phase && allParams.status) {
        countMinus += 1;
      }
      this.countFilter = Object.keys(allParams).length - countMinus;
    },
    actionSearch() {
      const filterActionParameters = {
        ...this.storageParams,
        ...this.advancedSearchParams,
      };
      sessionStorage.setItem(
        this.storageName,
        JSON.stringify(filterActionParameters)
      );
      this.handleCountFilter();
      this.$emit('handle-reset-button');
      this.$emit('handle-filter-count', this.countFilter);
    },
    actionSearchModal() {
      this.actionSearch();
      this.handleCloseModal();
    },
    handleSelectedDates() {
      if (this.storageParams[`status_date[end]`]) {
        const endDate = new Date(this.storageParams[`status_date[end]`]);
        this.advancedSearchParams[`status_date[end]`] = endDate;
      }

      if (this.storageParams[`status_date[start]`]) {
        const startDate = new Date(this.storageParams[`status_date[start]`]);
        this.advancedSearchParams[`status_date[start]`] = startDate;
      }

      if (this.storageParams.checkout_date) {
        const endDate = new Date(this.storageParams.checkout_date);
        this.advancedSearchParams.checkout_date = endDate;
      }

      if (this.storageParams.checkin_date) {
        const startDate = new Date(this.storageParams.checkin_date);
        this.advancedSearchParams.checkin_date = startDate;
      }
    },

    handleFollowUpDropdown() {
      if (this.storageParams.handled) {
        this.advancedSearchParams.handled =
          this.storageParams?.handled === 1 ? 'followed' : 'notFollowed';
      }
    },

    handleCloseModal() {
      this.isShowModal = false;
      this.$emit('close-filter-modal');
    },

    handleResetParameters() {
      this.filterProperties.forEach(e => {
        delete this.advancedSearchParams[e];
      });
      sessionStorage.removeItem(this.storageName);
      sessionStorage.setItem(
        this.storageName,
        JSON.stringify(this.advancedSearchParams)
      );
      this.phases = this.phaseOptions;
      this.$emit('handle-filter-count', 0);
      this.handleCountFilter();
    },

    handleResetSearch() {
      this.handleResetParameters();
      this.handleCloseModal();
    },
  },
};
</script>
<style lang="scss" scoped src="./FIlterActionModal.scss"></style>
