<template>
  <div
    class="action-bar"
    :class="{ 'action-bar__hide-action': !showSearchType }"
  >
    <bg-dropdown
      v-if="showSearchType"
      v-model="open"
      data-testid="btn-drop-down"
      class="action-bar__search-type"
      :close-on-select="true"
      :triggerText="searchLabel"
      trigger-size="lg"
    >
      <bg-dropdown-item
        v-for="(search, key) in searchListType"
        :key="key"
        :active="searchType === search.value"
        @click="updateSearchType(search.value)"
        >{{ search.label }}</bg-dropdown-item
      >
    </bg-dropdown>
    <div class="action-bar__search">
      <bg-search-bar
        v-model="searchValue"
        :placeholder="searchPlaceholder"
        data-testid="search-field"
        @keyup.enter="handleSearch"
        @input.native="handleNumber"
        size="lg"
      />
    </div>
    <div class="action-bar__button action-bar__filter">
      <bg-button
        icon="filter"
        size="lg"
        block
        @click.native="handleToggleFilterModal"
      >
        <bg-badge-counter
          v-if="filterModalCount > 0"
          variant="black"
          :value="filterModalCount"
        />
        <bg-icon v-else name="filter" />
        <span>Filter</span>
      </bg-button>
    </div>
    <div class="action-bar__button">
      <bg-button variant="primary" size="lg" @click="handleSearch" block
        >Cari</bg-button
      >
    </div>
    <div class="action-bar__button">
      <bg-button
        variant="primary"
        size="lg"
        @click="handleResetSearch"
        :disabled="isResetDisabled"
        block
        >Reset</bg-button
      >
    </div>
    <filter-action-modal
      ref="filter_action_modal"
      :show-modal="isShowModalFilter"
      :is-profile="isProfile"
      @close-filter-modal="handleToggleFilterModal()"
      @handle-reset-button="isResetDisabled = false"
      @handle-filter-count="countFilterModal"
      @action-search="actionSearch"
    />
  </div>
</template>

<script>
import {
  BgDropdown,
  BgDropdownItem,
  BgSearchBar,
  BgButton,
  BgIcon,
  BgBadgeCounter,
} from 'bangul-vue';
import { dateFormatterToSend } from 'Utils/formatter';
import TenantCommunicationMixins from '../../mixins/tenantTracker';
import FilterActionModal from '../FilterActionModal';

export default {
  name: 'TopActionBar',
  data() {
    return {
      open: false,
      searchType: 'tenant_name',
      searchValue: '',
      searchParam: {},
      storageParams: {},
      isResetDisabled: true,
      isShowModalFilter: false,
      filterModalCount: 0,
      objectProperties: [
        'tenant_name',
        'tenant_phone_number',
        'kost_name',
        'status_date[start]',
        'status_date[end]',
        'checkin_date',
        'checkout_date',
        'bse',
        'phase',
        'status',
        'handled',
      ],
      searchBoxProperty: ['kost_name', 'tenant_name', 'tenant_phone_number'],
    };
  },

  props: {
    showSearchType: {
      type: Boolean,
      default: true,
    },
    defaultSearchType: {
      type: String,
      default: '',
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BgDropdown,
    BgDropdownItem,
    BgSearchBar,
    BgIcon,
    BgButton,
    BgBadgeCounter,
    FilterActionModal,
  },

  mixins: [TenantCommunicationMixins],

  computed: {
    searchPlaceholder() {
      return `Cari berdasarkan ${this.searchLabel}`;
    },
    searchLabel() {
      const usedSearchType = this.defaultSearchType
        ? this.defaultSearchType
        : this.searchType;
      return this.searchListType.find(type => type.value === usedSearchType)
        .label;
    },
    storageName() {
      return this.isProfile ? 'searchParamsProfile' : 'searchParams';
    },
    chosenSearchType() {
      return this.isProfile ? this.defaultSearchType : this.searchType;
    },
  },

  created() {
    this.sessionStorage = JSON.parse(sessionStorage.getItem(this.storageName));
    /* istanbul ignore else */
    if (!this.sessionStorage) return;
    this.objectProperties.forEach(property => {
      if (this.sessionStorage[property]) {
        /* istanbul ignore else */
        if (this.searchBoxProperty.includes(property)) {
          this.searchValue = this.sessionStorage[property];
          this.isResetDisabled = false;
        }
      }
    });
  },

  methods: {
    countFilterModal(count) {
      this.filterModalCount = count;
      if (count > 0) {
        this.isResetDisabled = false;
      }
    },

    updateSearchType(newValue) {
      this.handleResetSearch();
      this.searchType = newValue;
    },

    actionSearch() {
      this.storageParams = JSON.parse(sessionStorage.getItem(this.storageName));

      /* istanbul ignore else */
      if (this.storageParams[`status_date[start]`]) {
        const startDate = dateFormatterToSend(
          this.storageParams[`status_date[start]`]
        );
        this.storageParams[`status_date[start]`] = startDate;
      }
      /* istanbul ignore else */
      if (this.storageParams[`status_date[end]`]) {
        const endDate = dateFormatterToSend(
          this.storageParams[`status_date[end]`]
        );
        this.storageParams[`status_date[end]`] = endDate;
      }
      if (this.storageParams.checkin_date) {
        const startDate = dateFormatterToSend(
          this.storageParams.checkin_date
        );
        this.storageParams.checkin_date = startDate;
      }
      /* istanbul ignore else */
      if (this.storageParams.checkout_date) {
        const endDate = dateFormatterToSend(
          this.storageParams.checkout_date
        );
        this.storageParams.checkout_date = endDate;
      }
      /* istanbul ignore else */
      if (this.storageParams.handled) {
        this.storageParams.handled =
          this.storageParams?.handled === 'followed' ? '1' : '0';
      }
      const filterActionParameters = {
        ...this.storageParams,
        ...this.searchParam,
      };
      this.$emit('action-search', filterActionParameters);
    },

    handleNumber() {
      if (this.searchType === 'tenant_phone_number') {
        this.searchValue = this.searchValue.replace(/[^0-9+]/g, '');
      }
    },

    handleToggleFilterModal() {
      this.isShowModalFilter = !this.isShowModalFilter;
    },

    handleSearch() {
      this.isResetDisabled = false;
      this.searchParam = {
        [this.chosenSearchType]: this.searchValue,
      };
      this.actionSearch();
    },

    handleDeleteParams() {
      this.searchValue = '';
      this.isResetDisabled = true;
      this.searchParam = {
        tenant_name: '',
        tenant_phone_number: '',
        kost_name: '',
      };
    },

    handleResetSearch() {
      this.handleDeleteParams();
      /* istanbul ignore else */
      if (this.filterModalCount > 0) {
        this.$refs.filter_action_modal.handleResetParameters();
      }
      this.filterModalCount = 0;
      sessionStorage.removeItem(this.storageName);
      this.$emit('action-search', null);
    },
  },
};
</script>

<style lang="scss" scoped src="./TopActionBar.scss"></style>
