<template>
  <bg-modal
    v-model="isShowModal"
    :title="modalTitle"
    :bodyScroll="false"
    :close-on-click-backdrop="false"
    @close="closeModal"
  >
    <bg-text size="body-landing" class="mt-8 mb-24">{{ confirmationText }}</bg-text>
    <div v-if="!isLoadData">
      <bg-grid class="mt-4">
        <bg-grid-item :col="6">
          <bg-text size="body-1">Nama Properti</bg-text>
          <bg-text size="body-2">
            {{ checkinData.room_type_name }}
          </bg-text>
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-text size="body-1">Informasi Penyewa</bg-text>
          <bg-text size="body-2">{{ checkinData.user_name }} </bg-text>
          <bg-link
            size="body-2"
            variant="medium"
            data-testid="tenant-phone-number"
            @click="handleRedirectToWA(checkinData.user_phone_number)"
            >{{ checkinData.user_phone_number }}</bg-link>
        </bg-grid-item>
      </bg-grid>
      <!-- Checkin section -->
      <template v-if="isCheckInType">
        <bg-grid>
          <bg-grid-item :col="6">
            <bg-text size="body-1">Tanggal Check-in</bg-text>
            <bg-text size="body-2">{{
              mixDateFormatterToDisplay(
              checkinData.checkin_date,
              'DD MMM YYYY'
              )
              }}
            </bg-text>
          </bg-grid-item>
          <bg-grid-item :col="6">
            <bg-text size="body-1">Hitungan / Durasi</bg-text>
            <bg-text size="body-2">{{
              generateDurationContract(checkinData)
              }}</bg-text>
          </bg-grid-item>
        </bg-grid>
        <bg-grid>
          <bg-grid-item :col="6">
            <bg-text size="body-1">Jumlah Penyewa</bg-text>
            <bg-text size="body-2">{{ totalTenant }}</bg-text>
            <bg-text size="body-2">{{ tenantFamilyInfo }}</bg-text>
          </bg-grid-item>
          <bg-grid-item :col="6">
            <bg-text size="body-1">Pembayaran </bg-text>
            <bg-text size="body-2">{{ paymentStatus }}</bg-text>
          </bg-grid-item>
        </bg-grid>
      </template>
      <!-- Checkin section -->
      <!-- Checkout Section -->
      <template v-else>
        <bg-grid>
          <bg-grid-item :col="6">
            <bg-text size="body-1">Durasi Sewa</bg-text>
            <bg-text size="body-2">{{
              generateDurationContract(checkinData)
              }}</bg-text>
          </bg-grid-item>
          <bg-grid-item :col="6">
            <bg-text size="body-1">Deposit </bg-text>
            <bg-text size="body-2">{{
              setPriceToIDR(checkinData.deposit)
              }}</bg-text>
          </bg-grid-item>
        </bg-grid>

        <bg-grid>
          <bg-grid-item :col="6">
            <bg-text size="body-1">Tanggal Check-in</bg-text>
            <bg-text size="body-2">
              {{
              mixDateFormatterToDisplay(
                checkinData.checkin_date,
                'DD MMM YYYY'
                )
              }}
            </bg-text>
          </bg-grid-item>
          <bg-grid-item :col="6">
            <bg-text size="body-1">Tanggal Check-out</bg-text>
            <bg-text size="body-2">
              {{
                mixDateFormatterToDisplay(
                checkinData.checkout_date,
                'DD MMM YYYY'
                )
              }}
            </bg-text>
          </bg-grid-item>
        </bg-grid>
        <bg-grid>
          <bg-grid-item :col="6">
            <bg-text size="body-1">Konfirmasi Tanggal Check-out</bg-text>
            <bg-datepicker
              fixed-position="top"
              placeholder="Pilih tanggal di sini"
              v-model="dateCheckout"
              :error="!!checkoutDateErrorMessage"
              :disabled="canEditCheckoutDate"
              data-testid="checkout-date"
              />
            <bg-text
              class="checkin-checkout-modal__text-danger"
              v-if="checkoutDateErrorMessage"
              size="label-2"
              >{{checkoutDateErrorMessage}}</bg-text>
          </bg-grid-item>
        </bg-grid>
      </template>
      <!-- Checkout Section -->
    </div>
    <skeleton-loader v-else :columns="2" :row="3" />
    <template #footer>
      <div class="checkin-checkout-modal__footer">
        <bg-button
          class="checkin-checkout-modal__footer-btn"
          variant="tertiary"
          @click="closeModal"
          >Batalkan
        </bg-button>
        <bg-button
          variant="primary"
          @click="handleConfirmation"
          :loading="isLoadData || isLoadPostConfirmation"
          :disabled="!isCheckInType && !checkinData.can_confirm_checkout">{{
          btnActionText }}
        </bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import {
  BgModal,
  BgGrid,
  BgGridItem,
  BgText,
  BgLink,
  BgDatepicker,
  BgButton
} from 'bangul-vue';
import SkeletonLoader from '../SkeletonLoader';
import formatter from '@/_admin/mixins/formatter';
import tenantBookingPhase from '../../mixins/tenantBookingPhase';
import TenantTrackerApi from '@/_admin/api/endpoints/tenant-tracker';
import { rupiahFormatter } from '@/utils/formatter';

export default {
  name: 'CheckInCheckOutModal',

  data() {
    return {
      isShowModal: false,
      isLoadData: true,
      isLoadPostConfirmation: false,
      checkinData: '',
      dateCheckout: new Date(),
      errorMessage: 'Terjadi Kesalahan saat melakukan konfirmasi',
      checkoutDateErrorMessage: '',
      canEditCheckoutDate: true
    };
  },

  components: {
    BgModal,
    BgGrid,
    BgText,
    BgLink,
    BgGridItem,
    BgButton,
    BgDatepicker,
    SkeletonLoader,
  },

  mixins: [formatter, tenantBookingPhase],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    trackerId: {
      type: [Number, String],
      default: '',
    },

    bookingId: {
      type: [String, Number],
      default: '',
    },

    confirmationType: {
      type: String,
      default: 'check-in',
    },
  },

  computed: {
    confirmationText() {
      return this.isCheckInType
        ? 'Anda akan melakukan konfirmasi check-in:'
        : 'Anda akan melakukan konfirmasi check-out dan mengakhiri kontrak sewa penyewa:';
    },

    modalTitle() {
      return `Yakin ingin konfirmasi ${this.confirmationType} penyewa?`;
    },

    isCheckInType() {
      return this.confirmationType === 'check-in';
    },

    btnActionText() {
      return this.isCheckInType
        ? 'Konfirmasi Check-in'
        : 'Konfirmasi Check-out';
    },
    totalTenant() {
      return `${this.checkinData.guest_total} Penyewa,`;
    },

    tenantFamilyInfo() {
      return this.generateInfoTenantFamily(this.checkinData);
    },

    paymentStatus() {
      const indexOfContent = 0;
      return this.checkinData.invoices[indexOfContent]?.payment_status;
    },
  },

  watch: {
    showModal: {
      immediately: true,
      handler(isOpenModal) {
        this.isShowModal = isOpenModal;
        /* istanbul ignore else */
        if (isOpenModal && this.trackerId) {
          this.getTenantCheckinData();
        }
      },
    },
    dateCheckout(value) {
      if (value) {
        this.checkoutDateErrorMessage = '';
      }
    }
  },

  methods: {
    closeModal() {
      this.dateCheckout = null;
      this.checkoutDateErrorMessage = '';
      this.isShowModal = false;
      this.$emit('close-modal');
    },

    handleRedirectToWA(phoneNumber) {
      window.open(`https://wa.me/${phoneNumber}`);
      window.opener = null;
    },

    setPriceToIDR(price) {
      /* istanbul ignore else */
      if (price) return rupiahFormatter(price);

      return 'Rp 0';
    },

    handleConfirmation() {
      if (this.isCheckInType) {
        this.handleCheckinConfirmation();
      } else {
        if (this.dateCheckout) {
          this.handleCheckoutConfirmation();
        }
        
        this.checkoutDateErrorMessage = this.dateCheckout ? '' : 'Masukkan konfirmasi tanggal check-out.'
      }
    },

    async handleCheckinConfirmation() {
      try {
        this.isLoadPostConfirmation = true;
        const res = await TenantTrackerApi.postConfirmationCheckIn(
          this.bookingId
        );
        if (res.data?.checkin_time) {
          this.$emit('confirmation-success');
        } else {
          alert('Terjadi Kesalahan saat melakukan konfirmasi');
          return;
        }
        this.closeModal();
      } catch (error) {
        alert(`${this.errorMessage} ${this.confirmationType}`);
        console.error(error.response.data);
      } finally {
        this.isLoadPostConfirmation = false;
      }
    },

    async handleCheckoutConfirmation() {
      const formattedDate = this.mixDateFormatterToDisplay(new Date(this.dateCheckout), 'YYYY-MM-DD');
      try {
        this.isLoadPostConfirmation = true;
        const res = await TenantTrackerApi.postConfirmationCheckout(
          this.bookingId,
          {
            ended_date: formattedDate
          }
        );
        if (res.data?.checkout_date) {
          this.$emit('confirmation-success');
        } else {
          alert('Terjadi Kesalahan saat melakukan konfirmasi');
          return;
        }
        this.closeModal();
      } catch (error) {
        alert(`${this.errorMessage} ${this.confirmationType}`);
        console.error(error.response.data);
      } finally {
        this.isLoadPostConfirmation = false;
      }
    },

    async getTenantCheckinData() {
      try {
        this.isLoadData = true;
        const res = await TenantTrackerApi.getPhaseDetail({
          trackerId: this.trackerId,
          phase: this.isCheckInType ? 'checkin' : 'checkout',
        });

        if (res.data?.tracker) {
          this.checkinData = res.data.tracker;
          this.canEditCheckoutDate = !!this.checkinData?.checkout_request_date;
          this.dateCheckout = this.checkinData?.checkout_request_date ? new Date(this.checkinData?.checkout_request_date) : null;
        } else {
          alert(`${this.errorMessage} ${this.confirmationType}`);
        }
      } catch (error) {
        alert(`${this.errorMessage} ${this.confirmationType}`);
        console.error(error.response);
      } finally {
        this.isLoadData = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./CheckinCheckoutModal.scss">
</style>