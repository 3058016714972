<template>
  <div class="filter-action">
    <bg-modal
      v-model="isShowModal"
      :button-main-text="saveBtnText"
      button-second-text="Kembali"
      :closeOnClickBackdrop="false"
      desktop-size="md"
      @close="handleCloseModal"
      @click-main-action="handlePostNotes"
      @click-second-action="handleCloseModal"
    >
      <bg-text size="heading-4">Catatan</bg-text>
      <bg-text class="filter-action__subtitle" size="input-lg"
        >Tambahkan catatan untuk data ini</bg-text
      >
      <bg-textarea
        id="textarea"
        v-model="value"
        placeholder="Tulis di sini..."
        :max-char="300"
        :max-length="300"
      />
      <bg-text size="label-2" class="filter-action__maximum-text"
        >Maksimal 300 karakter</bg-text
      >
    </bg-modal>
  </div>
</template>

<script>
import { BgModal, BgText, BgTextarea } from 'bangul-vue';
import CommunicationProfileApi from '@admin_endpoints/tenant-tracker';

export default {
  name: 'CreateNoteModal',
  data() {
    return {
      value: '',
      saveBtnText: 'Simpan',
      isShowModal: false,
    };
  },

  components: {
    BgText,
    BgModal,
    BgTextarea,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    noteValue: {
      type: String,
      default: '',
    },
    trackerId: {
      type: [Number, String],
      default: '',
    },
    trackerStatus: {
      type: String,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    noteId: {
      type: [String, Number],
      default: '',
    },
  },

  watch: {
    showModal(isOpen) {
      this.isShowModal = isOpen;
    },
    noteValue() {
      this.value = this.$props.noteValue;
    },
  },

  methods: {
    handleCloseModal(source) {
      this.isShowModal = false;
      this.$emit('close-note-modal');
      if (source === 'history') {
        this.$emit('open-note-modal');
      }
      this.value = '';
    },
    async handlePostNotes() {
      try {
        this.saveBtnText = 'Loading...';
        let response;
        if (this.isEdit) {
          response = await CommunicationProfileApi.putTrackerNotes({
            noteId: this.noteId,
            message: this.value,
          });
        } else {
          response = await CommunicationProfileApi.postTrackerNotes({
            trackerId: this.trackerId,
            status: this.trackerStatus,
            message: this.value,
          });
        }

        if (response.data) {
          this.$emit('update-success');
        } else {
          alert('Terjadi galat, silakan coba lagi.');
          console.error(response.data);
        }
      } catch (error) {
        alert('Terjadi galat, silakan coba lagi.');
        console.error(error);
      } finally {
        this.saveBtnText = 'Simpan';
      }

      this.value = '';
    },
  },
};
</script>
<style lang="scss" scoped src="./CreateNoteModal.scss"></style>
