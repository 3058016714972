export default {
  methods: {
    generateContractType(contract) {
      const contractType = {
        daily: 'Hari',
        weekly: 'Minggu',
        monthly: 'Bulan',
        quarterly: '3 Bulan',
        semiannually: '6 Bulan',
        yearly: 'Tahun',
      };

      return contractType[contract];
    },

    generateDurationContract(bookingData) {
      const unitDuration = { quarterly: 3, semiannually: 6 };
      const halfDurationType = ['quarterly', 'semiannually'];
      const contractType = bookingData.contract_type;
      const contractDuration = bookingData.contract_duration;
      const labelContractType = this.generateContractType(contractType);
      const partialCalculation = contractDuration * unitDuration[contractType];
      /* istanbul ignore next */
      const labelDuration = halfDurationType.includes(contractType)
        ? `${partialCalculation} Bulan`
        : `${contractDuration} ${labelContractType}`;

      return `Per ${labelContractType}  /
         ${labelDuration} `;
    },

    generateInfoTenantFamily(bookingData) {
      const isMarried = bookingData.guest_info?.is_married
        ? 'Pasangan suami istri'
        : '';
      const isBringChild = bookingData.guest_info?.is_bring_child
        ? 'Bawa anak'
        : '';
      const separator = isMarried && isBringChild ? ',' : '';

      const tenantFamilyInfo =
        isMarried || isBringChild
          ? `${isMarried}${separator} ${isBringChild}`
          : '-';

      return tenantFamilyInfo;
    },
    
  },
};
