import bseOptions from 'Utils/data/bseOptions.json';

export default {
  data() {
    return {
      headers: [
        {
          text: 'Profil Penyewa',
        },
        {
          text: 'Nama Properti',
        },
        {
          text: 'Fase',
        },
        {
          text: 'Status',
        },
        {
          text: 'Tanggal Status',
        },
        {
          text: 'Catatan',
        },
        {
          text: 'Owner',
        },
        {
          text: 'Action',
        },
      ],
      bseOptions,
      statusOptions: {
        survei: [
          {
            label: 'Diajukan',
            val: 'waiting',
          },
          {
            label: 'Hari H Survei',
            val: 'on_survey',
          },
          {
            label: 'Dibatalkan',
            val: 'cancelled',
          },
        ],
        booking: [
          {
            label: 'Butuh Konfirmasi',
            val: 'booked',
          },
          {
            label: 'Tunggu Pembayaran',
            val: 'confirmed',
          },
          {
            label: 'Terbayar Lunas',
            val: 'paid',
          },
          {
            label: 'Dibatalkan',
            val: 'cancelled',
          },
          {
            label: 'Dibatalkan oleh Admin',
            val: 'cancel_by_admin',
          },
          {
            label: 'Kedaluwarsa',
            val: 'expired',
          },
          {
            label: 'Kedaluwarsa oleh Admin',
            val: 'expired_by_owner',
          },
          {
            label: 'Ditolak',
            val: 'rejected',
          },
          {
            label: 'Diakhiri',
            val: 'terminated',
          },
          {
            label: 'Terbayar DP',
            val: 'paid_dp',
          },
        ],
        chat: [
          {
            label: 'Butuh Cepat',
            val: 'Saya butuh cepat nih. Bisa booking sekarang?',
          },
          {
            label: 'Diskon',
            val: 'Ada diskon untuk kos ini?',
          },
          {
            label: 'Ingin Survei',
            val: 'Saya ingin survei dulu',
          },
          {
            label: 'Masih Ada Kamar',
            val: 'Masih ada kamar?',
          },
          {
            label: 'Tanya Alamat',
            val: 'Alamat kos di mana?',
          },
          {
            label: 'Kontak Pemilik',
            val: 'Cara menghubungi pemilik?',
          },
          {
            label: 'Tanya-tanya Dulu',
            val: 'Boleh tanya-tanya dulu?',
          },
          {
            label: 'Pasutri',
            val: 'Bisa pasutri?',
          },
          {
            label: 'Bawa Hewan',
            val: 'Boleh bawa hewan?',
          },
          {
            label: 'Bisa Harian',
            val: 'Bisa sewa harian?',
          },
          {
            label: 'Whatsapp',
            val: 'whatsapp',
          },
        ],
        checkin: [
          {
            label: 'Menunggu Check-in',
            val: 'ready_to_checkin',
          },
          {
            label: 'Hari H Check-in',
            val: 'd_day_checkin',
          },
          {
            label: 'Belum Check-in',
            val: 'no_checkin_confirmation',
          },
          {
            label: 'Sudah Check-in',
            val: 'checked_in',
          },
          {
            label: 'Sewa Diperpanjang',
            val: 'rental_extended',
          },
        ],
        checkout: [
          {
            label: 'H-1 Check-out',
            val: 'd-1_checkout',
          },
          {
            label: 'Hari-H Check-out',
            val: 'd_day_checkout',
          },
          {
            label: 'Belum Check-out',
            val: 'no_checkout_confirmation',
          },
          {
            label: 'Penyewa Ajukan Berhenti Sewa',
            val: 'rental_termination_submitted',
          },
          {
            label: 'Sudah Check-out',
            val: 'finished',
          },
        ],
      },
      phaseOptions: [
        {
          label: 'Chat',
          val: 'chat',
        },
        {
          label: 'Survei',
          val: 'survei',
        },
        {
          label: 'Booking',
          val: 'booking',
        },
        {
          label: 'Check-in',
          val: 'checkin',
        },
        {
          label: 'Check-out',
          val: 'checkout',
        },
      ],
      searchListType: [
        {
          label: 'Nama Penyewa',
          value: 'tenant_name',
        },
        {
          label: 'No. HP Penyewa',
          value: 'tenant_phone_number',
        },
        {
          label: 'Nama Properti',
          value: 'kost_name',
        },
      ],
    };
  },

  computed: {
    actions() {
      return [
        {
          title: 'follow-up',
          actionClick: ({ handleStatus = '', listId = '' }) => {
            this.updateHandlingStatus(handleStatus, listId);
          },
          icon: 'file-booking',
        },
        {
          title: 'Tambah track status chat WA',
          actionClick: () => {
            this.handleWhatsappTrackModal(false);
          },
          icon: 'whatsapp',
        },
        {
          title: `Konfirmasi tenant ${this.confirmationType}`,
          actionClick: ({ listId = '', funnelId = '', status = '' }) => {
            this.handleShowCheckInCheckOutModal(listId, funnelId, status);
          },
          icon: 'check-round',
        },
      ];
    },
  },

  methods: {
    generateFollowUpStatus(isHandled) {
      return isHandled ? 'Tandai belum follow-up' : 'Tandai sudah follow-up';
    },
  },
};
